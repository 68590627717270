import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styles/App.css'
import styles from '../styles/Home.module.css'
import GoogleMapView, { FlightPath, Location } from '../components/GoogleMapsView';


export interface IHomeProps { }

const Home: React.FunctionComponent<IHomeProps> = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const location: Location[] = [
        { coords: { lat: 55.87160613264678, lng: -4.289749671577372 }, url: "icons/glasgowchapel.png" },
        { coords: { lat: 55.86480263466177, lng: -4.299624115497312 }, url: "icons/factory.png" },
        { coords: { lat: 55.877865936598594, lng: -4.2893993185791 }, url: "icons/palmtree.png" },
    ];

    return (
        <div>
            <h1>ANNI X BRODIE</h1>

            <p className={styles.intro}>{t('home-intro')}</p>

            <img src="images/kibble-front-web.png" alt="kibble palace" />

            <h2 className={styles.dayone}>DAY 1</h2>
            <h3 className={styles.dayone}>university chapel</h3>
            <p className={styles.dayone}>{t('Day-1-text1')}</p>

            <h3 className={styles.dayone}>the warehouse</h3>
            <p className={styles.dayone}>{t('Day-1-text2')}</p>

            <h2 className={styles.daytwo}>DAY 2</h2>
            <h3 className={styles.daytwo}>kibble palace</h3>
            <p className={styles.daytwo}>{t('Day-2-text')}</p>
            <GoogleMapView
                lat={55.88160613264678}
                lng={-4.289749671577372}
                zoom={13.6}
                flightPathCoordinates={[]}
                locations={location}
            />

        </div >
    );
};

export default Home;
